import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import {InView} from "react-intersection-observer";
import Slider from "../../shared/slick-wrapper";
import LazyImage from "../../shared/lazy-image";
import getFilteredMediaData from "../../../utils/helpers/get-filtered-media-data";
import { MEDIA_DEVICE_TYPES } from "../../../constants/super-app-constants";
import { NUMBER, WHITE_LOGO_URL } from "../../../constants/app-constants";
import { Heading } from "@cars24/turbo-web/lib/heading";
// import { getLogoURL } from "../../../constants/url-constants";
import { useTokens } from "@cars24/turbo-web/lib/theme-provider";
import { sendCustomGaEvents } from "../../../tracking";
import { heroBannerView, heroBannerClick } from "../../../constants/tracking-constants/super-home-events";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";

const SuperWebLandingBanner = ({
    superApp,
    sectionKey,
    platformLabel,
    setIsQrVisible = () => {}
}) => {
    const { tokens } = useTokens();
    const { parsedData, isQrVisible } = superApp || {};
    const { data } = parsedData[sectionKey];
    const { content: bannerContentList = []} = data || {};

    const onBannerClickHandler = async (bannerLabel) => {
        await yieldToMain();
        sendCustomGaEvents({
            ...heroBannerClick,
            eventLabel: `${bannerLabel} | ${platformLabel}`
        });
    };

    const onChangeViewHandler = (inView, bannerLabel) => {
        if (inView) {
            sendCustomGaEvents({
                ...heroBannerView,
                eventLabel: `${bannerLabel} | ${platformLabel}`
            });
        }
        if (!(isQrVisible === null && inView)) {
            setIsQrVisible(!inView);
        }
    };

    const getBannerData = () => {

        const filteredBannerData = getFilteredMediaData(bannerContentList, MEDIA_DEVICE_TYPES.DESKTOP);
        const appBanners = filteredBannerData.map((bannerItem, index) => {

            const { key, media, bannerId } = bannerItem || {};
            const { url: bannerUrl, meta: mediaMetaData } = (media || {})[MEDIA_DEVICE_TYPES.DESKTOP] || {};
            const {
                hasDescription,
                textWithLogo,
                textList,
                bannerLabel
            } = mediaMetaData || {};
            let imageComponent;

            if (index === NUMBER.ZERO || index === filteredBannerData.length - NUMBER.ONE) {
                imageComponent =
                    (<picture styleName={"styles.picture"} width="1350" height="633" >
                        <source media="(min-width: 1023px)" srcSet={bannerUrl} type="image/webp" />
                        <img width="1350" height="633" src={bannerUrl} alt="slide 1" fetchpriority="high" onClick={onBannerClickHandler.bind(null, bannerLabel)}/>
                    </picture>);

            } else {
                imageComponent = (<LazyImage
                    src={bannerUrl}
                    key={key}
                    alt={bannerId || key}
                    onClick={onBannerClickHandler.bind(null, bannerLabel)}
                />);
            }

            return (
                <InView threshold={1} onChange={(inView) => onChangeViewHandler(inView, bannerLabel)} key={key}>
                    <div styleName={"styles.sliderItem"} >
                        {
                            hasDescription &&
                        <div styleName={"styles.customContainer"}>
                            <div styleName={"styles.bannerHeading"}>
                                {
                                    textWithLogo &&
                                    <Heading
                                        level={4}
                                        color={tokens.text_default_text_inverse}
                                        isBold
                                    >
                                        {textWithLogo}
                                        <img src={WHITE_LOGO_URL} alt="cars24" width={80} height={36}/>
                                    </Heading>
                                }
                                {
                                    <React.Fragment>
                                        <Heading level={1} color={tokens.text_default_text_inverse}>
                                            {
                                                (textList || []).map((text, idx) => (

                                                    <>{text} {idx !== textList.length - 1 && <br />} </>
                                                ))
                                            }
                                        </Heading>

                                    </React.Fragment>
                                }
                            </div>
                        </div>
                        }
                        {
                            imageComponent
                        }
                    </div>
                </InView>
            );

        });

        return appBanners || [];

    };

    const settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        arrows: false,
        dots: true,
        infinite: false

    };
    return (
        <InView as="div" styleName={"styles.slideWrapper"}>

            <Slider {...settings} >
                {
                    getBannerData()
                }

            </Slider>
        </InView>
    );
};

SuperWebLandingBanner.propTypes = {
    superApp: PropTypes.object,
    sectionKey: PropTypes.string,
    platformLabel: PropTypes.string,
    setIsQrVisible: PropTypes.func
};

export default SuperWebLandingBanner;

/* eslint-disable max-statements */
import React, { useState, useCallback } from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
// import InputText from "../../shared/input-text";

import debounce from "../../../utils/helpers/debounce";
// import searchIcon from "./images/search-icon.svg";
import { SearchInput } from "@cars24/turbo-web/lib/search-input";
import MakeModalSearchList from "../make-modal-search-list";
import TrendingSearchList from "../trending-search-list";
import { NUMBER, USER_LOGIN_MEDIUM, USER_LOGIN_STATUS } from "../../../constants/app-constants";
import { sendCustomGaEvents } from "../../../tracking";
import {
    clearEcommerce,
    noAutoSuggestions,
    semiAutoCompleteSuggestionsOnClickEnter,
    typeInitiated,
    autoCompleteSuggestionsOnClick,
    autoCompleteSuggestionsOnClickEnter,
    searchBarOnFocus,
    semiAutoCompleteSuggestionsOnClick
} from "./tracking-constants";
import { ga4CarSearchEvent } from "../../../constants/tracking-constants/ga4-ecommerce-event";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";
import { searchClicked } from "../../../constants/tracking-constants/super-home-events";
import { useAnimation } from "../../../utils/helpers/use-animation";

const textsToDisplay = ["make", "model", "fuel", "body-type", "transmission"];

const FindDreamCarsV2 = ({
    fetchCarSearchConnect,
    popularCars,
    applyFilterAndRedirectToListingPage,
    dimensionObject,
    isLoggedIn,
    mobile,
    homePage,
    searchPlaceHolder = "Search for your favourite cars",
    platformLabel,
    showAnimation
}) => {
    // const inputRef = useRef(null);
    const [searchInput, setsearchInput] = useState("");
    const [showTrendingSearch, setShowTrendingSearch] = useState(false);
    const [searchSuggestions, setSearchSuggestions] = useState([]);
    const [searchSuggestionsToShow, setSearchSuggestionsToShow] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const placeholder = showAnimation ? `Search cars by ${useAnimation(textsToDisplay, NUMBER.THREE_HUNDRED)}` : searchPlaceHolder;
    const invokeCarSearchEcomEvent = (searchInputValue) => {
        if (searchInputValue.length < NUMBER.ONE) return;
        sendCustomGaEvents(clearEcommerce);
        sendCustomGaEvents({
            ...ga4CarSearchEvent,
            search_term: searchInputValue,
            user_id: isLoggedIn ? mobile : "",
            user_status: isLoggedIn ? USER_LOGIN_STATUS.LOGGED_IN : USER_LOGIN_STATUS.LOGGED_OUT,
            login_type: isLoggedIn ? USER_LOGIN_MEDIUM.PHONE : USER_LOGIN_MEDIUM.NONE
        });
    };

    const fetchSearchResults = (searchValue) => {
        fetchCarSearchConnect(searchValue).then(data => {
            if (data && data.length > 0) {
                setSearchSuggestionsToShow(data);
            }
            setSearchSuggestions(data);
            if (!(data.length !== NUMBER.ZERO) && !homePage) {
                sendCustomGaEvents({
                    ...noAutoSuggestions,
                    eventAction: `${searchValue}`,
                    ...dimensionObject
                });
            }
        });
    };

    const delayedQuery = useCallback(
        debounce((val) =>
            fetchSearchResults(val)
        , NUMBER.THREE_HUNDRED),
        []
    );
    const handleInputChange = (event) => {
        const searchInputValue = event.target.value.replace("=", "");
        if (searchInputValue.length === NUMBER.ONE && searchInput.length < searchInputValue.length) {
            sendCustomGaEvents({
                ...typeInitiated,
                eventAction: `${searchInputValue}`,
                ...dimensionObject
            });
        }
        setsearchInput(searchInputValue);
        delayedQuery(searchInputValue);
    };

    const handleFilterSelect = ({option, index, isSaveToRecentSearch}) => {
        if (!option.query) {
            return;
        }
        setsearchInput("");
        const gaEventToFire = (searchSuggestions && searchSuggestions.length > 0) ? autoCompleteSuggestionsOnClick : semiAutoCompleteSuggestionsOnClick;
        const searchSuggestionsToFire = (searchSuggestions && searchSuggestions.length > 0) ? searchSuggestions : searchSuggestionsToShow;
        const searchKeyword = isSaveToRecentSearch ? { search: option.value } : null;
        applyFilterAndRedirectToListingPage({query: option.query, options: searchKeyword});
        setShowTrendingSearch(false);
        if (isSaveToRecentSearch && !homePage) {
            sendCustomGaEvents({
                ...gaEventToFire,
                eventAction: `${searchInput}`,
                eventLabel: `${index + 1} | ${searchSuggestionsToFire.slice(0, NUMBER.TEN).map(ss => ss.displayTitle).join(",")}`,
                ...dimensionObject
            });
            invokeCarSearchEcomEvent(searchInput);
        }
        // if (inputRef && inputRef.current) {
        //     inputRef.current.blur();
        // }
    };

    const getSearchData = (option) => {
        const { title = "", query = "", description = "", displayTitle = "" } = option;
        const searchData = {
            ...option,
            label: title || displayTitle || description,
            value: title || displayTitle || description,
            query
        };
        return searchData;
    };

    const handleKeyPress = (event) => {
        if (selectedIndex < 0) {
            if (event.key === "Enter") {
                if (searchSuggestions.length > 0) {
                    handleFilterSelect({option: getSearchData(searchSuggestions[0])});
                    sendCustomGaEvents({
                        ...autoCompleteSuggestionsOnClickEnter,
                        eventAction: `${searchInput}`,
                        eventLabel: `1 | ${searchSuggestions.slice(0, NUMBER.TEN).map(ss => ss.displayTitle).join(",")}`,
                        ...dimensionObject
                    });
                    invokeCarSearchEcomEvent(searchInput);
                } else {
                    handleFilterSelect({option: getSearchData(searchSuggestionsToShow[0])});
                    sendCustomGaEvents({
                        ...semiAutoCompleteSuggestionsOnClickEnter,
                        eventAction: `${searchInput}`,
                        eventLabel: `1 | ${searchSuggestionsToShow.slice(0, NUMBER.TEN).map(ss => ss.displayTitle).join(",")}`,
                        ...dimensionObject
                    });
                }
            }
        }
    };

    const onInputFocus = async () => {
        // if (inputRef && inputRef.current) {
        //     inputRef.current.focus();
        //     setSelectedIndex(-1);
        //     setShowTrendingSearch(true);
        //     if (searchInput) {
        //         fetchSearchResults(searchInput);
        //     }
        // }
        // if (inputRef && inputRef.current) {
        //     inputRef.current.focus();
        setSelectedIndex(-1);
        setShowTrendingSearch(true);
        if (searchInput) {
            fetchSearchResults(searchInput);
        }

        await yieldToMain();

        if (homePage) {
            sendCustomGaEvents({
                ...searchClicked,
                eventLabel: platformLabel
            });
        } else {
            sendCustomGaEvents({
                ...searchBarOnFocus,
                eventCategory: !homePage ? "Buy_used_cars_B2C_Car_Listing_Page" : "Buy_used_cars_B2C_Car_Home_Page",
                eventLabel: "sticky",
                ...dimensionObject
            });

        }

    };

    const onInputBlur = () => {
        window.setTimeout(() => {
            setShowTrendingSearch(false);
            setSearchSuggestions([]);
        }, NUMBER.TWO_HUNDRED);
    };

    const updateSearchSuggestionsOnArrowKeys = (inpSelected) => {
        if (inpSelected) setsearchInput(inpSelected.displayTitle);
    };

    const onClearSearch = () => {
        setsearchInput("");
    };

    return (
        <div styleName={"styles.searchWrap"}>
            <div styleName={"styles.inputWrap"}>
                {/* <InputText
                    placeholder={!showTrendingSearch ? "Search for your favourite cars" : ""}
                    value={searchInput}
                    inputRef={inputRef}
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPress}
                    onFocusCallback={onInputFocus}
                    onBlurCallback={onInputBlur}
                    maxLength={NUMBER.THOUSAND}
                /> */}
                {/* <span styleName={"styles.searchIcon"}>
                    <img width="24" height="24" src={searchIcon} alt="Search Brand" />
                </span> */}
                <SearchInput
                    placeholder={!showTrendingSearch ? placeholder : ""}
                    value={searchInput}
                    // inputRef={inputRef}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyPress}
                    onFocusCallback={onInputFocus}
                    onBlurCallback={onInputBlur}
                    onClear={onClearSearch}
                    showCross={false}
                />
            </div>
            <div styleName={"styles.box"}>
                {
                    searchInput && searchInput.length > 0
                        ? !!(searchSuggestionsToShow && searchSuggestionsToShow.length > 0) && (<MakeModalSearchList
                            searchSuggestions={searchSuggestionsToShow}
                            handleSearchSuggestionSelect={(searchSuggestion, index) => handleFilterSelect({option: getSearchData(searchSuggestion), index, isSaveToRecentSearch: true})}
                            searchInput={searchInput}
                            updateSearchSuggestionsOnArrowKeys={updateSearchSuggestionsOnArrowKeys}
                            setSelectedIndex={setSelectedIndex}
                            selectedIndex={selectedIndex}
                        />)
                        : (!!showTrendingSearch && popularCars && popularCars.length > 0 && <TrendingSearchList
                            trendingSearch={popularCars}
                            handleTrendingSearchSelect={(popularCar) => handleFilterSelect({option: getSearchData(popularCar)})}
                            dimensionObject={dimensionObject}
                        />)
                }
            </div>
        </div>
    );
};

FindDreamCarsV2.propTypes = {
    fetchCarSearchConnect: PropTypes.func,
    fetchPresetFiltersConnect: PropTypes.func,
    selectedCity: PropTypes.object,
    location: PropTypes.object,
    popularCars: PropTypes.array,
    history: PropTypes.object,
    pincode: PropTypes.string,
    reloadCarListConnect: PropTypes.func,
    selectedFilters: PropTypes.object,
    fetchFiltersConnect: PropTypes.func,
    dimensionObject: PropTypes.object,
    applyFilterAndRedirectToListingPage: PropTypes.func,
    isLoggedIn: PropTypes.func,
    mobile: PropTypes.func,
    homePage: PropTypes.bool,
    searchPlaceHolder: PropTypes.string,
    platformLabel: PropTypes.string,
    showAnimation: PropTypes.bool
};

export default FindDreamCarsV2;
